
import { useRouter } from 'vue-router'
import { formatDateTime } from '@/utils/dateTime'
import { formatterDollars } from '@/utils/formatter'
import { defineComponent, ref, computed, reactive } from 'vue'
import SlotError from '@/components/common/SlotError.vue'
import { useNotification } from '@/composables'
import { useResource } from '@/services/useResource'
import userProductRepositories from '@/repositories/userProductRepositories'
import MapStock from './MapStock.vue'
import { useStore } from 'vuex'
import { ElMessageBox, ElNotification } from 'element-plus'
import { imageUrl } from '@/utils/image'
import { ArrowDown } from '@element-plus/icons'

export default defineComponent({
  components: { ArrowDown, SlotError, MapStock },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { success, error, warning } = useNotification()
    const { updatePersonalizeStatus } = userProductRepositories()
    const { getters } = useStore()
    const user = computed(() => getters['user/getUser'])
    let resource = 'seller-teams'
    let selected = ref({})
    let loading = ref(false)
    const router = useRouter()
    const columns = [
      {
        title: 'Created',
        dataIndex: 'createdDate',
        minWidth: 15,
      },
      // {
      //   title: 'Handle',
      //   dataIndex: 'handle',
      // },
      {
        title: 'Title',
        dataIndex: 'title',
        minWidth: 45,
      },
      {
        title: 'Mockup',
        dataIndex: 'mockup',
        minWidth: 15,
        align: 'center',
      },
      {
        title: 'Type',
        dataIndex: 'personalized',
        minWidth: 15,
        align: 'center',
      },
      {
        title: 'Product type',
        dataIndex: 'productType',
        minWidth: 20,
      },
      {
        title: 'Note',
        dataIndex: 'note',
        minWidth: 15,
      },
      // {
      //   title: 'Option hash',
      //   dataIndex: 'optionHash',
      //   minWidth: 25,
      // },
      // {
      //   title: 'Action',
      //   dataIndex: 'action',
      //   minWidth: 25,
      //   align: 'center',
      // },
      // {
      //   title: 'Cost',
      //   dataIndex: 'cost',
      //   minWidth: '100%'
      // },
      // {
      //   title: 'Catalog Code',
      //   dataIndex: 'catalogCode',
      //   minminWidth_width: '100%'
      // },
    ]

    let multipleSelection = ref<any>([])
    const handleSelectionChange = (val: []) => {
      multipleSelection.value = val
    }

    const selectedIds = computed(() => {
      return multipleSelection.value.map((product: any) => product._id)
    })

    const disableMapping = computed(() => {
      const isChecked = multipleSelection.value.map(
        (product: any) => product._id
      )
      const check = multipleSelection.value.find((item: any) => {
        return item.optionHash !== multipleSelection.value[0]?.optionHash
      })
      if (
        (isChecked.length > 0 && check !== undefined) ||
        isChecked.length === 0
      ) {
        return true
      }
      return false
    })

    const mappedUser = ref<any>(null)

    const state = reactive({
      mappedUsers: [],
    })

    let userInfo = undefined
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
      state.mappedUsers = userInfo.mappedUsers
    } catch (e) {
      return error("Can't find user info")
    }

    // console.log('user info-->', userInfo)

    const handleMapping = () => {
      if (multipleSelection.value?.length <= 0) {
        ElNotification({
          title: 'Warning',
          message: 'Please select product.',
          offset: 100,
          type: 'warning',
        })
        return
      }
      const check = multipleSelection.value.find((item: any) => {
        return item.optionHash !== multipleSelection.value[0]?.optionHash
      })
      if (check) {
        error(`optionHash different`)
      } else if (multipleSelection.value.length === 1) {
        router.push({
          path: `/s/mapping-product/${multipleSelection.value[0]._id}`,
        })
      } else {
        // console.log(
        //   'array of id',
        //   multipleSelection.value.map((s: any) => s._id).toString()
        // )
        router.push({
          path: `/s/mapping-products/${multipleSelection.value
            .map((s: any) => s._id)
            .toString()}`,
        })
      }
    }
    const fectchRecord = async (id: string) => {
      const { get } = useResource(resource)
      const { data, status } = await get(id)

      if (status === 200) {
        mappedUser.value = data as any
        loading.value = false
      } else {
        error('Opps...Unexpected error. Please try again latter!')
      }
    }

    fectchRecord(user.value?.sellerTeam?.id)

    const mapStockDialog = ref<InstanceType<typeof MapStock>>()
    const openMapStockDialog = (row: any) => {
      selected.value = row
      // console.log('mapStockDialog -->', row)

      mapStockDialog.value?.toggle(row._id, mappedUser.value?.mappedUser)
    }

    const onUpdatePersonalizeProducts = async (personalizedStatus: boolean) => {
      try {
        // loc nhung product
        const productSelected = multipleSelection.value
          .map((item: any) => item)
          .filter(
            (item: any) =>
              personalizedStatus
                ? !item.personalized || item.personalized === false // loc sp co trang thai regular
                : item.personalized === true // loc sp co trang thai personalized
          )
        if (productSelected?.length === 0) {
          warning('No products to update.')
          return
        }
        const message = `Are you sure to update selected products?`
        ElMessageBox.confirm(message)
          .then(async () => {
            const res = (await updatePersonalizeStatus({
              ids: productSelected.map((item: any) => item._id),
              personalized: personalizedStatus,
            })) as any
            if (res?.status === 200 || res?.status === 201) {
              success(res?.data?.message || `Success`)
              // handleSelectionChange([])
            } else {
              error(res?.data?.message)
            }
          })
          .catch(err => {
            console.log(err)
          })
      } catch (e) {
        error()
      }
    }

    const click = (item: any) => {
      try {
        router.push({ name: 's.product-detail', params: { id: item._id } })
      } catch (error) {
        console.log(error)
      }
    }
    return {
      columns,
      MapStock,
      click,
      state,
      selected,
      loading,
      user,
      selectedIds,
      disableMapping,
      fectchRecord,
      openMapStockDialog,
      formatDateTime,
      mapStockDialog,
      formatterDollars,
      imageUrl,
      handleMapping,
      updatePersonalizeStatus,
      handleSelectionChange,
      onUpdatePersonalizeProducts,
    }
  },
})
