
import { computed, reactive, watch, ref, defineComponent } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData, useNotification } from '@/composables'
import List from './components/List.vue'
import { useRouter } from 'vue-router'
import Pagination from '@/components/common/Pagination.vue'
import SelectStores from '@/components/common/SelectStores.vue'
import userProductRepositories from '@/repositories/userProductRepositories'
import { ElMessageBox } from 'element-plus'

export default defineComponent({
  components: { sectionLayoutContent, List, Pagination, SelectStores },
  setup() {
    const breadcrumbs = ['Product', '']
    const title = 'Products'

    // const resource = `http://localhost:3003/api/products`
    const resource = `/products`

    const params = reactive({
      page: 1,
      limit: 10,
      title: null,
      handle: null,
      personalized: null,
      productType: null,
    })
    const router = useRouter()
    const { documents, fetchData, isLoading, total } = useFetchData(
      resource,
      params
    )
    fetchData()

    watch(params, fetchData)

    const create = () => {
      router.push({ name: 's.create-product' })
      console.log(`create`)
    }

    const titleExport = 'Export Products'

    const content = computed(() => {
      return { breadcrumbs, title, titleExport }
    })
    const onFilterCallback = (filter: any) => {
      let search = filter?.search
      params.title = search
    }

    const onClearPersonalized = () => {
      params.personalized = null
    }

    const { exportProducts } = userProductRepositories()
    const { success, error } = useNotification()

    const exportProductList = async () => {
      ElMessageBox.confirm(
        `Confirm to export ${total.value} products ?`,
        'Warning',
        {
          confirmButtonText: 'Export',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async () => {
        try {
          isLoading.value = true
          const res: any = await exportProducts(params)
          window.open(res?.data?.url, '_blank')
          success(res?.data?.message || `Export variants success`)
        } catch (e) {
          error('Error when export!')
        }
        isLoading.value = false
      })
    }

    return {
      content,
      documents,
      isLoading,
      onFilterCallback,
      activeKey: ref('1'),
      create,
      total,
      params,
      onClearPersonalized,
      exportProductList,
    }
  },
})
