
import { defineComponent, reactive, ref, watch } from 'vue'
import { updateHandler } from '@/services/updateHandler'
import { useResource } from '@/services/useResource'
import { stringRules } from '@/utils/formRules'

export default defineComponent({
  setup(props: any, context: any) {
    let productId = ref('')
    let mapperUserId = ref('')
    let dialogVisible = ref(false)
    let loading = ref(false)
    const mapStockForm = ref()
    let resource = 'products'

    const formData = reactive({
      product: {
        id: '',
        name: '',
        mockup: '',
        variants: [
          {title:'', checked:false}
        ],
        mappedProductId:'',
        mappedProducts:{
          productId: '',
          mappingVariantData:ref<any[]>([]),
        },
      },
      variants:ref<any[]>([]),
      refProduct: ref<any[]>([]),
    })

    const toggle = (id: string, mid: string) => {
      dialogVisible.value = !dialogVisible.value
      mapperUserId.value = mid
      productId.value = id
    }

    const formRules = {
      categories: stringRules('selectedProductId', 2, 128),
    }

    const fectchRecord = async (id: string) => {
      const { get } = useResource(resource)
      const { data, status } = await get(id)
      if (status === 200) {
        formData.product = data as any
        if(formData.product.mappedProducts){
          formData.product.mappedProductId = formData.product.mappedProducts.productId
        }
        let productVariants = formData.product.variants;
        productVariants.forEach(i =>{
          i.checked = formData.product.mappedProducts.mappingVariantData?.find((i:any) => {return i.title1 === i.title})
        })
        //formData.product.variants = 
      }
    }

    const getMapProducts = async (uId: string) => {
      const { getAll } = useResource(resource)
      const params = reactive({
        page: 0,
        limit: 1000,
        sellerTeam: mapperUserId.value,
      })
      const { data, status } = await getAll(params)
      if (status === 200) {
        formData.refProduct = data as any
        
        if(formData.product && formData.refProduct){
          formData.variants = formData.refProduct.filter((s:any) =>  s._id === formData.product.mappedProducts.productId)[0].variants
        }
      }
    }
    const onChangeProduct = async () => {
      console.log('variant-selected -=>', formData.refProduct)
      console.log('variant-selected -=>', formData.refProduct.filter(s=>  s._id ===formData.product.mappedProducts.productId))
      formData.variants = formData.refProduct.filter(s=> s._id ===formData.product.mappedProductId)[0].variants
    }
    const watchModalOpen = watch(dialogVisible, () => {
      if (dialogVisible.value === true) {
        formData.variants = []
        fectchRecord(productId.value)
        getMapProducts(mapperUserId.value)
      }
    })

    const onSubmit = async () => {
      const resource = `products/variant/mapping/${productId.value}`
      let patchData = {
        productId: formData.product.mappedProducts.productId,
        mappingVariantData:[]
      }
      mapStockForm.value.validate(async (valid: any) => {
        if (valid) {
          const { edit } = useResource(resource)
          loading.value = true
          const { status } = await edit(patchData)
          if (status === 200) {
            dialogVisible.value = false
            context.emit('change')
          }
          loading.value = false
        }
      })
    }

    return {
      mapStockForm,      
      onSubmit,
      dialogVisible,
      loading,
      formData,
      toggle,
      watchModalOpen,
      fectchRecord,
      onChangeProduct,
      formRules,
    }
  },
})
