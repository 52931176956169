
import useShopRepositories from '@/repositories/useShopRepositories'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const { getAllStores } = useShopRepositories()
    const stores = ref()
    const isLoading = ref(true)
    const getStoreList = async () => {
      stores.value = await getAllStores()
      isLoading.value = false
    }
    getStoreList()

    return { getStoreList, stores, isLoading }
  },
})
